// const price = (number) => '€' + number?.toFixed(2);

const price = (number) => {
    const formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    });

    return formatter.format(number);
};

const percentage = (number) => number?.toFixed(2) + '%';

const decimal = (number) => number.toFixed(2);

const flag = (code) => String.fromCodePoint(
    ...code.toUpperCase().split('').map(char =>  127397 + char.charCodeAt())
);
const kilometers = (number) => number.toFixed(2) + 'km';

const truncate = (text, length = 100) => {
    if (text.length > length) {
        return text.slice(0, length) + "...";
    } else {
        return text;
    }
};

export {
    price, percentage, decimal, kilometers, flag, truncate
};
